import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import * as St from "../../Styled"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  DATA_TYPE_ARTIST_PODCAST,
} from "../../../constants/props"
import { AppContext } from "../../../contexts/AppContext"

import useRecordUserData from "../../../hooks/useRecordUserData"

const PodcastCard = props => {
  const {
    updatePlayer,
    selectedCountry,
    device,
    userState,
    ctxPlayer,
  } = useContext(AppContext)

  const recordUserData = useRecordUserData({
    type: DATA_TYPE_ARTIST_PODCAST,
    id: props.id,
    user: userState ? userState.id : null,
    viewed: props.viewed != null ? props.viewed : 0,
  })

  const isPlaying =
    ctxPlayer.id === props.id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_PODCAST &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.PodcastSliderWrappper>
      <St.PodcastSliderImageWrapper>
        <StyledImage
          image={props.image.url}
          width={"100%"}
          height={"100%"}
          playing={isPlaying}
          premium={props.premium}
          imageAlt={props.title}
          borderRadius={
            getConfigValue(device, "podcasts.slider.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO, // podcasts are just audios
                id: props.id,
                title: props.title,
                preview: props.image.url,
                subtitle: props.station && props.station.title,
                uri: props.uri,
                playlist: props.playlist,
                dataType: DATA_TYPE_ARTIST_PODCAST,
                playing: true,
                artist: props.artist, // artist is owner of this podcast
                station: null,
              })
              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.PodcastSliderImageWrapper>
      <St.PodcastSliderInfoWrapper>
        {selectedCountry && (
          <St.PodcastsSliderTitle>{props.title}</St.PodcastsSliderTitle>
        )}

        <St.PodcastSliderReadMoreSection
          string={props.about}
          offset={getConfigValue(device, "podcasts.slider.textOffset")}
        />
      </St.PodcastSliderInfoWrapper>
    </St.PodcastSliderWrappper>
  )
}

export default PodcastCard
