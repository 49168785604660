import React from "react"
import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import { MEDIA_TYPE_AUDIO, MEDIA_TYPE_VIDEO } from "../../constants/props"
import Slider from "react-slick"
import { Icon } from "rsuite"

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: visible;
  align-self: flex-start;
  position: relative;
`
const LeftArrow = styled.div`
  position: absolute;
  transform: translateY(-50%);
  left: -35px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: none;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LANDSCAPE.videos.slider.image.height / 2 + "vw"
        : props.type === MEDIA_TYPE_AUDIO
        ? LANDSCAPE.podcasts.slider.image.widthHeight / 2 + "vw"
        : LANDSCAPE.presenters.slider.image.widthHeight / 2 + "vw"};
  }
  @media (min-width: ${TAB.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? TAB.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? TAB.podcasts.slider.image.widthHeight / 2
        : TAB.presenters.slider.image.widthHeight / 2}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? MEDIUM.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? MEDIUM.podcasts.slider.image.widthHeight / 2
        : MEDIUM.presenters.slider.image.widthHeight / 2}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    left: -40px;
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LARGE.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? LARGE.podcasts.slider.image.widthHeight / 2
        : LARGE.presenters.slider.image.widthHeight / 2}px;
  }
`

const RightArrow = styled.div`
  position: absolute;
  text-align: start;
  transform: translateY(-50%);
  width: 30px;
  right: -35px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: none;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LANDSCAPE.videos.slider.image.height / 2 + "vw"
        : props.type === MEDIA_TYPE_AUDIO
        ? LANDSCAPE.podcasts.slider.image.widthHeight / 2 + "vw"
        : LANDSCAPE.presenters.slider.image.widthHeight / 2 + "vw"};
  }
  @media (min-width: ${TAB.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? TAB.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? TAB.podcasts.slider.image.widthHeight / 2
        : TAB.presenters.slider.image.widthHeight / 2}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? MEDIUM.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? MEDIUM.podcasts.slider.image.widthHeight / 2
        : MEDIUM.presenters.slider.image.widthHeight / 2}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    right: -40px;
    top: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LARGE.videos.slider.image.height / 2
        : props.type === MEDIA_TYPE_AUDIO
        ? LARGE.podcasts.slider.image.widthHeight / 2
        : LARGE.presenters.slider.image.widthHeight / 2}px;
  }
`
const ArrowIcon = styled(Icon)`
  font-size: 20px;
  color: #a2a2a2;
  cursor: pointer;
`
const SliderCards = styled.div`
  overflow: hidden;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.contentWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`

// IN MOBILE WE WOULD SHOW PARTS OF NEXT/PREVIOUS IMAGES RATHER THAN ARROW
// FOR THAT WE WOULD ADD PADDING ON LEFT/RIGHT SIDE ENOUGH TO SHOW PART OF NEXT PREVIOUS CARDS
// IN CASE THERE ARE ONLY IMAGES ENOUGH TO FILL VISIBLE ROW PARENT COMPONENTS USING SLIDER
// CONTAINER WOULD NEED TO PASS ADD PADDING AS FALSE IN ORDER TO SHOW CARDS LEFT ALIGNED

const SliderCardsExtended = styled.div`
  flex-wrap: nowrap;

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0px
      ${props =>
        props.addPadding
          ? props.type === MEDIA_TYPE_VIDEO
            ? MOBILE.videos.slider.paddingLeftRight
            : props.type === MEDIA_TYPE_AUDIO
            ? MOBILE.podcasts.slider.paddingLeftRight
            : MOBILE.presenters.slider.paddingLeftRight
          : 0}vw;
    width: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? MOBILE.videos.slider.cardRow +
          (props.addPadding ? MOBILE.videos.slider.paddingLeftRight * 2 : 0)
        : props.type === MEDIA_TYPE_AUDIO
        ? MOBILE.podcasts.slider.cardRow +
          (props.addPadding ? MOBILE.podcasts.slider.paddingLeftRight * 2 : 0)
        : MOBILE.presenters.slider.cardRow +
          (props.addPadding
            ? MOBILE.presenters.slider.paddingLeftRight * 2
            : 0)}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LANDSCAPE.videos.slider.cardRow
        : props.type === MEDIA_TYPE_AUDIO
        ? LANDSCAPE.podcasts.slider.cardRow
        : LANDSCAPE.presenters.slider.cardRow}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? TAB.videos.slider.cardRow
        : props.type === MEDIA_TYPE_AUDIO
        ? TAB.podcasts.slider.cardRow
        : TAB.presenters.slider.cardRow}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? MEDIUM.videos.slider.cardRow
        : props.type === MEDIA_TYPE_AUDIO
        ? MEDIUM.podcasts.slider.cardRow
        : MEDIUM.presenters.slider.cardRow}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${props =>
      props.type === MEDIA_TYPE_VIDEO
        ? LARGE.videos.slider.cardRow
        : props.type === MEDIA_TYPE_AUDIO
        ? LARGE.podcasts.slider.cardRow
        : LARGE.presenters.slider.cardRow}px;
  }
`
const SliderMain = ({
  children,
  showLeftArrow,
  showRightArrow,
  settings,
  addPadding = false,
  type, // type of card 'audio', 'video'
}) => {
  let slider = null
  const next = () => {
    slider.slickNext()
  }
  const previous = () => {
    slider.slickPrev()
  }

  return (
    <SliderContainer>
      <LeftArrow type={type}>
        {showLeftArrow && (
          <ArrowIcon icon="arrow-left" size="2x" onClick={previous} />
        )}
      </LeftArrow>
      <SliderCards>
        <SliderCardsExtended addPadding={addPadding} type={type}>
          <Slider ref={c => (slider = c)} {...settings}>
            {children}
          </Slider>
        </SliderCardsExtended>
      </SliderCards>
      <RightArrow type={type}>
        {showRightArrow && (
          <ArrowIcon icon="arrow-right" size="2x" onClick={next} />
        )}
      </RightArrow>
    </SliderContainer>
  )
}

export default SliderMain
