import React, { useContext, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { QUERY_ARTIST_BY_ID } from "../../queries/page/artist"
import { AppContext } from "../../contexts/AppContext"
import * as St from "../Styled"
import ArtistBox from "./ArtistBox"
import PodcastHList from "./PodcastHList"
import VideoHList from "./VideoHList"
import { Link } from "gatsby"

import { LANDSCAPE } from "../../constants/breakpoints"
import {
  artistPodcastsPageURL,
  artistVideosPageURL,
  convertToSlug,
} from "../../constants/props"

import moment from "moment"
const basePrefix = `${process.env.STRAPI_SERVER_URL}`

const PodcastHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`
const VideoHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin-top: 30px;
`
const LinkPage = styled(Link)`
  cursor: pointer;
  color: #fff;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(65px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`

const helper_podcasts = podcasts => {
  if (!podcasts || !podcasts.length) return []

  const result = podcasts.map((podcast, _) => {
    const v = {
      ...podcast,
      width: "135",
      height: "135",
      image: {
        ...podcast.image,
        url:
          podcast.image && podcast.image && podcast.image.url
            ? podcast.image.url.startsWith("http")
              ? podcast.image.url
              : basePrefix + podcast.image.url
            : "",
      },
    }
    return v
  })
  return result
}

const helper_videos = videos => {
  if (!videos || !videos.length) return []

  const result = videos.map((video, _) => {
    const v = {
      ...video,
      clickableTitle: false,
      width: "320",
      height: "180",
      image:
        video.image && video.image && video.image.url
          ? video.image.url.startsWith("http")
            ? video.image.url
            : basePrefix + video.image.url
          : "",
      subtitles: [
        "",
        `${moment(video.updatedAt)
          .startOf("seconds")
          .fromNow()}`,
      ],
    }
    return v
  })
  return result
}

const ArtistContent = props => {
  const { selectedCountry } = useContext(AppContext)

  const [pageState] = useState({
    neighbours: props.neighbours,
    data: {
      person: {
        ...props.data.person,
        podcasts: helper_podcasts(
          props.data.person && props.data.person.audios
        ),
        videos: helper_videos(props.data.person && props.data.person.videos),
      },
    },
  })

  const artistID =
    pageState.data && pageState.data.person && pageState.data.person.id
      ? pageState.data.person.id
      : ""
  const artistNameSlug =
    pageState.data && pageState.data.person && pageState.data.person.title
      ? convertToSlug(pageState.data.person.title)
      : ""

  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        {pageState && pageState.data && pageState.data.person && (
          <ArtistBox person={pageState.data.person} />
        )}
        <St.CenterContentSection>
          {(pageState.data.person &&
            pageState.data.person.audios &&
            pageState.data.person.audios.length > 0) ||
          (pageState.data.person &&
            pageState.data.person.videos &&
            pageState.data.person.videos.length > 0) ? (
            <St.CenterContentWrapper>
              {pageState.data &&
              pageState.data.person &&
              pageState.data.person.audios.length > 0 ? (
                <PodcastHListWrapper>
                  <St.PodcastSliderHeading>
                    <LinkPage
                      to={
                        selectedCountry && selectedCountry.id
                          ? artistPodcastsPageURL(
                              artistID,
                              artistNameSlug,
                              selectedCountry.id,
                              convertToSlug(selectedCountry.title)
                            )
                          : "/"
                      }
                    >
                      Top Singles
                    </LinkPage>
                  </St.PodcastSliderHeading>

                  <PodcastHList
                    data={
                      pageState.data &&
                      pageState.data.person &&
                      pageState.data.person.audios
                    }
                    artist={{
                      id: artistID,
                      slug: artistNameSlug,
                    }}
                  />
                </PodcastHListWrapper>
              ) : null}

              {pageState.data &&
              pageState.data.person &&
              pageState.data.person.videos.length ? (
                <VideoHListWrapper>
                  <St.VideoSliderHeading>
                    <LinkPage
                      to={
                        selectedCountry && selectedCountry.id
                          ? artistVideosPageURL(
                              artistID,
                              artistNameSlug,
                              selectedCountry.id,
                              convertToSlug(selectedCountry.title)
                            )
                          : ""
                      }
                    >
                      Videos
                    </LinkPage>
                  </St.VideoSliderHeading>

                  <VideoHList
                    data={
                      pageState.data &&
                      pageState.data.person &&
                      pageState.data.person.videos
                    }
                    artist={{
                      id: artistID,
                      slug: artistNameSlug,
                    }}
                  />
                </VideoHListWrapper>
              ) : null}
            </St.CenterContentWrapper>
          ) : (
            <St.NoDataMessage style={{ alignItems: "flex-start" }}>
              Stay tuned for Podcasts and Videos from this artist
            </St.NoDataMessage>
          )}
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

const ContentArtistDetails = props => {
  let { data, loading } = useQuery(QUERY_ARTIST_BY_ID, {
    variables: {
      id: props.artistID,
    },
    fetchPolicy: "network-only",
  })

  return !loading && <ArtistContent data={data} />
}

export default ContentArtistDetails
