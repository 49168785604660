import React, { useContext } from "react"
import styled from "styled-components"
import StyledImage from "../../StyledImage"

import {
  MEDIA_TYPE_VIDEO,
  getConfigValue,
  DATA_TYPE_ARTIST_VIDEO,
} from "../../../constants/props"
import * as St from "../../Styled"
import { AppContext } from "../../../contexts/AppContext"
import useRecordUserData from "../../../hooks/useRecordUserData"

const VideoCardWrappper = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  & div {
    border: none;
  }
`
const VideoCard = props => {
  const { updatePlayer, device, userState, ctxPlayer } = useContext(AppContext)
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_ARTIST_VIDEO,
    id: props.id,
    user: userState ? userState.id : null,
    viewed: props.viewed != null ? props.viewed : 0,
  })

  const isPlaying =
    ctxPlayer.id === props.id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_VIDEO &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <VideoCardWrappper>
      <St.VideoSliderImageWrapper>
        <StyledImage
          ismobile={props.ismobile}
          image={props.image}
          width={"100%"}
          height={"100%"}
          playing={isPlaying}
          premium={props.premium}
          borderRadius={"0px"}
          imageAlt={props.title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id: props.id,
                title: props.title,
                preview: props.image.url,
                uri: props.uri,
                playlist: props.playlist,
                station: null, // Just to clear that artist is the owner of playing video
                artist: {
                  id: props.artist.id,
                  slug: props.artist.slug,
                },
                viewed: props.viewed != null ? props.viewed : 0,
                dataType: DATA_TYPE_ARTIST_VIDEO,
                playing: true,
              })
              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.VideoSliderImageWrapper>
      <St.VideoSliderInfoWrapper>
        <St.VideoSliderTitle>{props.title}</St.VideoSliderTitle>
        <St.VideoSliderReadMoreSection
          string={props.about}
          offset={getConfigValue(device, "videos.slider.textOffset")}
        >
          {props.about ? props.about : ""}
        </St.VideoSliderReadMoreSection>
      </St.VideoSliderInfoWrapper>
    </VideoCardWrappper>
  )
}

export default VideoCard
