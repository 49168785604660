import React, { useState, useContext } from "react"
import PodcastCard from "./PodcastCard"
import SliderContainer from "../../SliderContainer"
import { MEDIA_TYPE_AUDIO, getConfigValue } from "../../../constants/props"
import {
  DEVICE_MOBILE,
  LANDSCAPE,
  TAB,
  MOBILE,
} from "../../../constants/breakpoints"
import { AppContext } from "../../../contexts/AppContext"

const PodcastHList = props => {
  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "podcasts.slider.slides")

  const [state, setState] = useState({
    showLeftArrow: false,
    showRightArrow: props.data.length - slidesToShow <= 0 ? false : true,
  })

  if (!props.data.length) return null


  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    responsive: [
      {
        breakpoint: TAB.minWidth,
        settings: {
          slidesToShow: LANDSCAPE.podcasts.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          slidesToShow: MOBILE.podcasts.slider.slides,
          initialSlide: props.data.length > 0 ? 1 : 0,
          className: 'sibling-visible', // would make overflow visible and hence would allow displaying parts of siblings
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow:
          props.data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }

  return (
    <SliderContainer
      addPadding={
        device === DEVICE_MOBILE &&
        props.data.length > MOBILE.podcasts.slider.slides
          ? true
          : false
      }
      settings={settings}
      showLeftArrow={state.showLeftArrow}
      showRightArrow={state.showRightArrow}
      type={MEDIA_TYPE_AUDIO}
    >
      {props.data.map((item, i) => (
        <PodcastCard key={i} {...item} artist={props.artist}/>
      ))}
    </SliderContainer>
  )
}

export default PodcastHList
