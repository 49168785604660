import React, { useContext } from "react"
import styled from "styled-components"

import StyledImage from "../../StyledImage"
import ReadMorePara from "../../ReadMorePara"
import * as St from "../../Styled"

import topLeftImage from "../../../images/pages/top_left_image.png"
import {
  getConfigValue,
  convertToSlug,
  artistDetailsURL,
  DATA_TYPE_ARTIST
} from "../../../constants/props"
import { AppContext } from "../../../contexts/AppContext"

import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM
} from "../../../constants/breakpoints"

import LikeAndShare from "../../LikeAndShare"

const LeftColorLines = styled.img`
  position: absolute;
  z-index: 1;
  left: 0px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    top: 5px;
    width: 16%;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    top: 0px;
    width: 120px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    top: 35px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    top: 60px;
    width: 16%;
  }
`

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`

const ArtistBox = ({ person }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`

  let { device, selectedCountry } = useContext(AppContext)

  if (!person) return null

  const { id, title } = person

  const cover =
    person.cover && person.cover.url
      ? person.cover.url.startsWith("http")
        ? person.cover.url
        : basePrefix + person.cover.url
      : ""

  const profileImage =
    person.image && person.image.url
      ? person.image.url.startsWith("http")
        ? person.image.url
        : basePrefix + person.image.url
      : ""

  return (
    <>
      <LeftColorLines src={topLeftImage} />
      <St.ArtistsDetailsImageWrapper>
        <StyledImage
          image={cover}
          width={"100%"}
          height={"100%"}
          hover={false}
          profile={profileImage}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "box.artist.details.image.borderRadius") +
            "px"
          }
        />
      </St.ArtistsDetailsImageWrapper>
      <St.ArtistDetailsRightTextSection>
        <St.ArtistDetailsTitle>{person.title}</St.ArtistDetailsTitle>
        {person.location && (
          <St.ArtistDetailsSingleLineText>
            <b>Location: </b> <span>{person.location}</span>
          </St.ArtistDetailsSingleLineText>
        )}
        {person.contact && (
          <St.ArtistDetailsSingleLineText>
            <b>Artist Contact: </b> <span>{person.contact}</span>
          </St.ArtistDetailsSingleLineText>
        )}
        <St.ArtistDetailsMultiLineText>
          <div>About:</div>
          <ReadMorePara
            string={person.about}
            offset={getConfigValue(device, "box.artist.details.textOffset")}
          />
        </St.ArtistDetailsMultiLineText>

        <St.FeaturedSocialIconSection>
          <LikeShare>
            <LikeAndShare shareURL={artistDetailsURL(
              id,
              convertToSlug(title),
              selectedCountry ? selectedCountry.id : null,
              selectedCountry ? convertToSlug(selectedCountry.name) : null
            )}
            id={id}
            shareID={"featuredartist_"+id}
            likeType={DATA_TYPE_ARTIST}
            />
          </LikeShare>
        </St.FeaturedSocialIconSection>

      </St.ArtistDetailsRightTextSection>
    </>
  )
}

export const PersonBoxParent = ({ person }) => {
  return (
    <St.FeaturedBox noBackground={true}>
      <St.FeaturedBoxContent style={{ flex: 1 }}>
        <St.FeaturedCardWrapper>
          <ArtistBox person={person} />
        </St.FeaturedCardWrapper>
      </St.FeaturedBoxContent>
    </St.FeaturedBox>
  )
}

const ArtistBoxData = ({ person }) => {
  return <PersonBoxParent person={person}></PersonBoxParent>
}

export default ArtistBoxData
