import React from "react"
import queryString from "query-string"
import ContentArtistDetails from "../components/ContentArtistDetails"

const ArtistDetails = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}

  if (!queryParams.__aid) return <div>No such page</div>

  // if no selectCountry is yet in context this means user has directly landed to this page
  // in this case we will load selected country from server and set it as selected country
  return <ContentArtistDetails artistID={queryParams.__aid} />
}

export default ArtistDetails
