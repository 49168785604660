import gql from "graphql-tag"

export const CREATE_USER_MEDIACONTENT = gql`
  mutation CreateUserMediaContent($input: createMediacontentInput) {
    createMediacontent(input: $input) {
      mediacontent {
        id
      }
    }
  }
`

export const QUERY_ALL_USER_AUDIOS = gql`
  query AllUserAudios($userID: ID!) {
    audios: mediacontents(
      sort: "updatedAt:desc"
      where: { contenttype: "audio", owner: $userID }
    ) {
      id
      title
      uri
      image {
        id
        url
      }
      about
      mediaUri {
        id
        url
        name
        ext
      }
      presenter {
        id
        title
      }
      show {
        id
        title
      }
    }
  }
`
export const QUERY_ALL_USER_VIDEOS = gql`
  query AllUserVideos($userID: ID!) {
    videos: mediacontents(
      sort: "updatedAt:desc"
      where: { contenttype: "video", owner: $userID }
    ) {
      id
      title
      uri
      image {
        id
        url
      }
      about
      mediaUri {
        id
        url
        name
        ext
      }
      station {
        id
        title
      }
      presenter {
        id
        title
      }
    }
  }
`
export const MUTATION_UPDATE_USER_MEDIA = gql`
  mutation updateMediacontent($input: updateMediacontentInput) {
    updateMediacontent(input: $input) {
      mediacontent {
        id
        title
        uri
        image {
          id
          url
        }
        about
      }
    }
  }
`
export const MUTATION_DELETE_USER_MEDIA = gql`
  mutation deleteMediacontent($input: deleteMediacontentInput) {
    deleteMediacontent(input: $input) {
      mediacontent {
        id
      }
    }
  }
`
