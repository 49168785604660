import React, { useState, useContext } from "react"
import VideoCard from "./VideoCard"
import SliderContainer from "../../SliderContainer"
import { MEDIA_TYPE_VIDEO, getConfigValue } from "../../../constants/props"
import {
  LANDSCAPE,
  MOBILE,
  DEVICE_MOBILE,
  TAB,
} from "../../../constants/breakpoints"
import { AppContext } from "../../../contexts/AppContext"

const VideoHList = ({ artist, data }) => {
  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "videos.slider.slides")

  const [state, setState] = useState({
    showLeftArrow: false,
    showRightArrow: data.length - slidesToShow <= 0 ? false : true,
  })

  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,

    responsive: [
      {
        breakpoint: TAB.minWidth,
        settings: {
          slidesToShow: LANDSCAPE.videos.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          initialSlide: data.length > 0 ? 1 : 0,
          className: 'sibling-visible', // would make overflow visible and hence would allow displaying parts of siblings
          slidesToShow: MOBILE.videos.slider.slides,
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow: data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }

  if (!data.length) return null

  return (
    <SliderContainer
      addPadding={
        device === DEVICE_MOBILE &&
        data.length > MOBILE.videos.slider.slides
          ? true
          : false
      }
      settings={settings}
      showLeftArrow={state.showLeftArrow}
      showRightArrow={state.showRightArrow}
      type={MEDIA_TYPE_VIDEO}
    >
      {data.map((item, i) => (
        <VideoCard key={i} {...item} artist={artist} />
      ))}
    </SliderContainer>
  )
}

export default VideoHList
