import { useContext } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  QUERY_USER_DATA,
  MUTATION_ADD_USER_DATA,
  MUTATION_UPDATE_USER_DATA,
} from "../queries/page/user"

import { MUTATION_UPDATE_USER_MEDIA } from "../queries/page/mediacontent"
import auth from "../auth/auth"
import {
  DATA_TYPE_STATION,
  DATA_TYPE_ARTIST,
  APPSETTING_VIEW_COUNT_DELAY,
} from "../constants/props"
import moment from "moment"

import { AppContext } from "../contexts/AppContext"

// criteria would need to have user id in 'user' field and other field could be
// station/mediacontent/artist

/**
 * This component would be used to update/add user data whenever user plays any media/station/artist in order to collect data
 * and show that data on user likes page. User data should pass appropriate type (station/artist etc) along with id of
 * related entity.
 *
 */

const useRecordUserData = userData => {
  const { appSettings } = useContext(AppContext)
  const [addUserData] = useMutation(MUTATION_ADD_USER_DATA)
  const [updateUserData] = useMutation(MUTATION_UPDATE_USER_DATA)
  const [updateMedia] = useMutation(MUTATION_UPDATE_USER_MEDIA)

  const viewIncrementDelay =
    appSettings && appSettings[APPSETTING_VIEW_COUNT_DELAY]
      ? appSettings[APPSETTING_VIEW_COUNT_DELAY]
      : 10

  let criteria = {
    user: userData.user,
  }

  if (userData.type === DATA_TYPE_STATION) {
    criteria["station"] = userData.id
  } else if (userData.type === DATA_TYPE_ARTIST) {
    criteria["artist"] = userData.id
  } else {
    criteria["mediacontent"] = userData.id
  }

  const [recordUserData] = useLazyQuery(QUERY_USER_DATA, {
    variables: {
      where: criteria,
      limit: 1,
    },
    context: {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.userData && data.userData.length) {
        // user data related to criteria already exists so will be updating data
        let currentData = data.userData[0]
        let incrementPlayed = false

        // update viewed count only if user has played this media after 10 minutes
        if (
          currentData.lastIncremented &&
          moment().diff(moment(currentData.lastIncremented), "minutes") >
            viewIncrementDelay
        ) {
          incrementPlayed = true
        }

        updateUserData({
          variables: {
            input: {
              data: {
                playedCount: incrementPlayed
                  ? currentData.playedCount + 1
                  : currentData.playedCount,
                lastPlayed: new Date(),
                lastIncremented: incrementPlayed
                  ? new Date()
                  : currentData.lastIncremented,
              },
              where: {
                id: currentData.id,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        })

        // if viewed count is increased in this turn and this is media content, increment viewed of media as well

        if (
          incrementPlayed &&
          userData.type !== DATA_TYPE_STATION &&
          userData.type !== DATA_TYPE_ARTIST &&
          userData.viewed !== null
        ) {
          updateMedia({
            variables: {
              input: {
                data: {
                  viewed: userData.viewed + 1,
                },
                where: {
                  id: userData.id,
                },
              },
            },
            context: {
              headers: {
                Authorization: "Bearer " + auth.getToken(),
              },
            },
          })
        }
      } else {
        // No data found with criteria need to add new data in table
        addUserData({
          variables: {
            input: {
              data: {
                user: userData.user,
                type: userData.type,
                playedCount: 1,
                lastIncremented: new Date(),
                lastPlayed: new Date(),
                station:
                  userData.type === DATA_TYPE_STATION ? userData.id : null,
                artist: userData.type === DATA_TYPE_ARTIST ? userData.id : null,
                mediacontent:
                  userData.type !== DATA_TYPE_STATION &&
                  userData.type !== DATA_TYPE_ARTIST
                    ? userData.id
                    : null,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        }).then(response => {})

        // increment viewed
        if (
          userData.type !== DATA_TYPE_STATION &&
          userData.type !== DATA_TYPE_ARTIST &&
          userData.viewed != null
        ) {
          updateMedia({
            variables: {
              input: {
                data: {
                  viewed: userData.viewed + 1,
                },
                where: {
                  id: userData.id,
                },
              },
            },
            context: {
              headers: {
                Authorization: "Bearer " + auth.getToken(),
              },
            },
          })
        }
      }
    },
  })

  return recordUserData
}

export default useRecordUserData
